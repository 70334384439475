import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBPl0jj29tS0PQVElxyFQo9NnKuYH2JXEU",
  authDomain: "digital-rabbit-588d8.firebaseapp.com",
  databaseURL: "https://digital-rabbit-588d8.firebaseio.com",
  projectId: "digital-rabbit-588d8",
  storageBucket: "digital-rabbit-588d8.appspot.com",
  messagingSenderId: "638086028106",
  appId: "1:638086028106:web:f4be686764fd6046977fb3",
  measurementId: "G-S5T3SBMGNF"
};

let instance;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }

  return null;
}
