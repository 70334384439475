import React from 'react';

import UserProvider from '../../providers/UserProvider';

function RootLayout({ children }) {
  return (
    <UserProvider>
      {children}
    </UserProvider>
  );
}

export default RootLayout;