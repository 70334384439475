import React, { createContext, useEffect, useState } from "react";

import getFirebase from '../firebase';
const firebase = getFirebase();

export const UserContext = createContext({ user: null });

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    firebase && firebase.auth().onAuthStateChanged(userAuth => {
      setUser(userAuth);
    });
  }, []);

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
}
export default UserProvider;